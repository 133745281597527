/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import config from "../../config"

const Bio = () => {
  const avatarUrl = `/${config.SITE_DOMAIN}-logo.png`

  return (
    <div className="bio">
      {config.SITE_META_AUTHOR && avatarUrl && (
        <img
          alt={config.SITE_META_AUTHOR}
          height="80"
          width="80"
          className="bio-avatar"
          src={avatarUrl}
        />
      )}
      <div>
        {config.SITE_META_AUTHOR && (
          <div>
            Written by <strong>{config.SITE_META_AUTHOR}</strong>
            <div className="bio-author-descripition">
              {config.SITE_META_DESCRIPTION}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Bio
